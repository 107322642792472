/* eslint-disable fp/no-mutation */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import i18next from 'i18next'
import {setStormGuard} from 'lib/services/stormGuardAPI'
import {batteryProfileSelector, setBpDetailsValue, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import Analytics from 'lib/utils/analytics'
import {
  ACTION_TYPE,
  DIALOG_TYPE,
  PAGE_NAME,
  SOURCE_TYPE,
  STORM_GUARD_STATUS,
  TOAST_TYPE,
  TOGGLE_SWITCH_STATUS,
} from 'lib/utils/constants'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import Icon from 'lib/utils/icon'
import {isPvCtBatterySite, showStormGuard} from 'lib/utils/siteDetails'
import {isDemoUserBlocked, isUserHasAccess} from 'lib/utils/userDetails'
import {isSource, isWeb} from 'lib/utils/utility'
import {HTTPS_API_STATUS} from '../../../../services/httpRequest'
import {showToast} from '../../atoms/Toast'
import {ToggleSwitch, toggleStatusInfo} from '../../atoms/ToggleSwitch'
import {updateProfileSliceValues} from '../../organisms/ProfileDetails/pdCore'
import {
  getDescription,
  getInfoDescription,
  getInfoForEvDescription,
  getSgChangeDescription,
  getStormAlertDetails,
  getStormGuardState,
  getStormGuardToggleStatus,
} from '../../organisms/StormGuardDetails/sgCore'
import {setSgValue} from '../../organisms/StormGuardDetails/sgSlice'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Storm Guard
 * *********************** *********************** *********************** */

export const StormGuard = props => {
  const {showArrow} = props
  const batteryProfile = useSelector(batteryProfileSelector)
  const {loading, stormGuardState, evseStormEnabled} = useSelector(state => state.components.stormGuard)
  const {criticalAlertActive} = useSelector(state => state.components.stormAlert)
  const {details} = batteryProfile

  const sgTitle = i18next.t('bp1')
  const sgForEvTitle = i18next.t('bp413')
  let sgDescription = getDescription(stormGuardState)

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setToggleStatus = val => dispatch(setSgValue({stormGuardState: val}))
  const setEvseStormEnabledStatus = val => dispatch(setSgValue({evseStormEnabled: val}))
  const setRequestType = val => dispatch(setSgValue({requestType: val}))
  const setLoading = val => dispatch(setSgValue({loading: val}))
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))

  // useEffect on mount
  useEffect(() => {
    if (!loading) {
      updateProfileSliceValues({type: 'storm', dispatch, data: details})
    }

    if (
      !batteryProfile.loading &&
      details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE &&
      details?.showStormGuardAlert
    ) {
      showDialog(getStormAlertDetails({details, closeThisDialog, setBpDetails, setBatteryData}))
    }
  }, [batteryProfile.loading, details.stormGuardState])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // Handle Nav Arrow Action
  const onClickNavArrow = () => {
    if (showArrow) {
      Analytics.sendClickEvent('StormGuard_Page', 'StormGuard')
      setBpDetails({subPage: [...batteryProfile.subPage, PAGE_NAME.STORM_GUARD]})
    }
  }

  const getSgInfoDetails = () => {
    const sgInfoDescription = getInfoDescription()
    const obj = {
      className: 'sg__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: sgTitle,
      content: sgInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  const getEvInfoDetails = () => {
    const sgInfoDescription = getInfoForEvDescription()
    const obj = {
      className: 'sg__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: sgForEvTitle,
      content: sgInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  const getSgChangePopupDetails = checked => {
    const sgChangeDescription = getSgChangeDescription({status: checked, criticalAlertActive})
    const obj = {
      className: 'sg__change-popup',
      type: DIALOG_TYPE.CENTER,
      showDialog: true,
      showCloseIcon: false,
      title: sgTitle,
      content: sgChangeDescription,
      onClickCloseIcon: closeThisDialog,
    }

    obj.buttons = [
      {
        value: i18next.t('bp105'),
        action: cancelSgChangeDisclaimer,
        disabled: false,
        className: 'bp__ab--mobile-view',
      },
      {
        value: i18next.t('bp104'),
        action: () => enableSgChangeDisclaimer(checked),
        className: 'bp__ab--mobile-view',
      },
    ]

    return obj
  }

  // Storm Guard Change Popup :: On Click - No
  const cancelSgChangeDisclaimer = () => {
    closeThisDialog()
    setToggleStatus(stormGuardState)

    // on cancel click restore the ev charging checkbox state for ev + pv + ct + battery site
    if (showEvforStormGuard()) {
      setToggleStatus(getStormGuardToggleStatus(details.stormGuardState))
      setEvseStormEnabledStatus(details.evseStormEnabled)
    }
  }

  // Storm Guard Change Popup :: On Click - Yes
  const enableSgChangeDisclaimer = value => {
    closeThisDialog()

    // Check User Access
    if (!isUserHasAccess()) {
      setToggleStatus(stormGuardState)

      if (showEvforStormGuard()) {
        setToggleStatus(getStormGuardToggleStatus(details.stormGuardState))
        setEvseStormEnabledStatus(details.evseStormEnabled)
      }

      return
    }

    if (!isPvCtBatterySite()) {
      value === TOGGLE_SWITCH_STATUS.ON
        ? Analytics.sendClickEvent('IQEVSE_Storm_Guard_On', 'IQ_EVSE_Storm_Guard')
        : Analytics.sendClickEvent('IQEVSE_Storm_Guard_Off', 'IQ_EVSE_Storm_Guard')
    }

    setToggleStatus(value)
    save(value)
  }

  // Show Storm Guard Change Popup
  const showSgChangePopup = value => {
    const obj = getSgChangePopupDetails(value)
    showDialog(obj)
  }

  // On Click Info Icon
  const onClickInfo = e => {
    e.stopPropagation()

    const obj = getSgInfoDetails()
    showDialog(obj)
    Analytics.sendClickEvent('StormGuard_profile_info', 'Profile')
  }

  // On Click Info Icon for EV
  const onClickInfoForEv = e => {
    e.stopPropagation()

    const obj = getEvInfoDetails()
    showDialog(obj)
  }

  // Handle Toggle Change
  const handleToggleChange = value => {
    if (isDemoUserBlocked()) return

    setToggleStatus(value)

    /** Conditions to show popup directly on toggle change:
      1. STORM_GUARD_OPTOUT AB gating is disabled, show popup for all site configurations except where ev section for storm guard is shown
      2. STORM_GUARD_OPTOUT AB gating is enabled, show popup for all NON (ev + ct + pv + battery case)
      3. STORM_GUARD_OPTOUT AB gating is enabled, show popup for site where STORM_GUARD_EVSE_OPTOUT is false and it is a (ev + ct + pv + battery) site [No apply button] */
    if (
      (!isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) && !showEvforStormGuard()) ||
      (isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) && !isPvCtBatterySite()) ||
      (isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) &&
        !isFeatureSupported(FEATURE_KEYS.STORM_GUARD_EVSE_OPTOUT) &&
        isPvCtBatterySite())
    ) {
      showSgChangePopup(value)
    }

    if (showEvforStormGuard()) {
      // default state is unchecked
      setEvseStormEnabledStatus(false)
    }

    if (value === TOGGLE_SWITCH_STATUS.ON) Analytics.sendClickEvent('StormGuard_Toggle_Enable', 'StormGuard')
    else Analytics.sendClickEvent('StormGuard_Toggle_Disable', 'StormGuard')
  }

  // Render Toggle
  const renderToggle = () => {
    let disabled = false
    let tStatus = loading ? TOGGLE_SWITCH_STATUS.LOADING : stormGuardState

    // disable the toggle with the storm guard state(on/off) while loading is true for ev + pv + ct + battery site
    if (showEvforStormGuard() && loading) {
      disabled = true
      tStatus = stormGuardState
    }

    return (
      <>
        {isWeb() && renderToggleStatus()}

        <ToggleSwitch
          key="sg-toggle"
          id="sg-toggle"
          status={tStatus}
          disabled={disabled}
          handleChange={handleToggleChange}
        />
      </>
    )
  }

  // On Change EVSE storm checkbox
  const onChangeEvseEnable = () => {
    if (loading) {
      return
    }
    setEvseStormEnabledStatus(!evseStormEnabled)
  }

  // isInfoChanged function used to display Apply button
  const isInfoChanged = () => {
    if (
      loading ||
      getStormGuardToggleStatus(details?.stormGuardState) !== stormGuardState ||
      details?.evseStormEnabled !== evseStormEnabled
    ) {
      return true
    }
    return false
  }

  // on click apply button
  const onClickApplyButton = () => {
    if (getStormGuardToggleStatus(details?.stormGuardState) !== stormGuardState) {
      if (stormGuardState === TOGGLE_SWITCH_STATUS.ON) {
        evseStormEnabled
          ? Analytics.sendClickEvent('IQEVSE_Storm_Guard_On', 'IQ_EVSE_Storm_Guard')
          : Analytics.sendClickEvent('IQEVSE_Storm_Guard_Off', 'IQ_EVSE_Storm_Guard')
      } else {
        Analytics.sendClickEvent('IQEVSE_Storm_Guard_Off', 'IQ_EVSE_Storm_Guard')
      }
      showSgChangePopup(stormGuardState)
    } else {
      evseStormEnabled
        ? Analytics.sendClickEvent('IQEVSE_Storm_Guard_On', 'IQ_EVSE_Storm_Guard')
        : Analytics.sendClickEvent('IQEVSE_Storm_Guard_Off', 'IQ_EVSE_Storm_Guard')

      if (!isUserHasAccess()) {
        setEvseStormEnabledStatus(details.evseStormEnabled)
        return
      }

      save(getStormGuardToggleStatus(details?.stormGuardState))
    }
  }

  // Show EV section for storm guard ab gating check + ev + pv + ct + battery site
  const showEvforStormGuard = () => isFeatureSupported(FEATURE_KEYS.STORM_GUARD_EVSE_OPTOUT) && isPvCtBatterySite()

  // Show EV section for Storm Guard page
  const showEvSectionForStormGuard = () => showEvforStormGuard() && !showArrow

  if (showEvSectionForStormGuard()) {
    sgDescription = stormGuardState === TOGGLE_SWITCH_STATUS.ON ? i18next.t('bp208') : i18next.t('bp482')
  }

  if (showArrow && showEvforStormGuard() && batteryProfile.subPage.length === 0) {
    if (stormGuardState !== getStormGuardToggleStatus(details.stormGuardState)) {
      setToggleStatus(getStormGuardToggleStatus(details.stormGuardState))
    }
  }

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  // Save
  async function save(value) {
    if (!details?.stormGuardState) {
      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
      })

      setToggleStatus(getStormGuardToggleStatus(details?.stormGuardState))
      return
    }

    const obj = {
      stormGuardState: getStormGuardState(value),
    }

    // adding evseStormEnabled key in request body obj
    if (showEvforStormGuard()) {
      obj.evseStormEnabled = evseStormEnabled
    }

    setLoading(true)

    // Set Storm Guard
    try {
      const resProfile = await setStormGuard(obj)
      if (resProfile?.status === HTTPS_API_STATUS.SUCCESS) {
        if (showEvforStormGuard()) {
          // persist the state of stormGuardState as active if storm alert is active and do not modify the stormGuardState
          if (value !== TOGGLE_SWITCH_STATUS.OFF && details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE) {
            // eslint-disable-next-line fp/no-delete
            delete obj.stormGuardState
          }
        }

        setBatteryData(obj)
        showToast({
          type: TOAST_TYPE.SUCCESS,
          message: i18next.t('bp94'),
          autoClose: 3000,
        })
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.error('[Error] StormGuard - Save', error)
      setToggleStatus(stormGuardState)
      showToast({
        type: TOAST_TYPE.ERROR,
        message: i18next.t('bp68'),
        autoClose: 3000,
      })
    } finally {
      setRequestType(null)
      setLoading(false)
    }
  }

  /* *********************** *********************** *********************** *
   * Render Functions
   * *********************** *********************** *********************** */

  function renderToggleStatus() {
    // const onText = details.stormGuardState === STORM_GUARD_STATUS.ACTIVE ? STORM_GUARD_STATUS.ACTIVE : null
    // return toggleStatusInfo({loading, status: stormGuardState, onText})
    return toggleStatusInfo({loading, status: stormGuardState})
  }

  // Render component based on condition to the top right
  function renderRightComponent() {
    if (showArrow) {
      return (
        <div className="com__ad__action">
          <Icon src="leftArrow" className="arrow-right" />
        </div>
      )
    }

    return <div className="sg__toggle">{renderToggle()}</div>
  }

  // render warning Text
  function renderWarningText() {
    if (details?.evseStormEnabled !== evseStormEnabled && evseStormEnabled) {
      return (
        <div className="bp__card-view--desc sg__ev__warning">
          <Icon src="warning" />
          <div className="bp__card-view--desc warning__text">{i18next.t('bp417')}</div>
        </div>
      )
    }
    return null
  }

  // Render EV component on storm guard page for ev + pv + ct + battery
  function renderEvForStormGuard() {
    if (showEvSectionForStormGuard()) {
      return (
        <div className="sg__ev__section">
          <div className="bp__card-view--title sg__ev__title">
            {sgForEvTitle}
            <div className="sg__info" onClick={onClickInfoForEv} onKeyDown={() => {}} role="button" tabIndex={0}>
              <Icon src="info" />
            </div>
          </div>
          <div className="bp__radio-checkbox__button sg__ev__wrapper">
            <input
              type="checkbox"
              checked={evseStormEnabled}
              disabled={loading || stormGuardState === TOGGLE_SWITCH_STATUS.OFF}
              onChange={() => onChangeEvseEnable()}
            />
            <div className="bp__card-view--desc sg__ev__checkbox">{i18next.t('bp269')}</div>
          </div>
          {renderWarningText()}
        </div>
      )
    }
    return null
  }

  // Render Apply button on storm guard page for ev + pv + ct + battery
  function renderApplyButton() {
    if (showEvSectionForStormGuard() && isInfoChanged()) {
      return (
        <IntractiveButton
          className="sg__ev__button ib__btn"
          type={ACTION_TYPE.SAVE}
          label={i18next.t('bp210')}
          loading={loading}
          loadingIcon="loaderV2"
          onClick={() => onClickApplyButton()}
        />
      )
    }
    return null
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Storm Guard
  if (!showStormGuard()) {
    return null
  }

  return (
    // Storm Guard
    <section
      id="bp-storm-guard"
      className={classNames('bp__storm-guard bp__card--storm-guard', {
        'bp__sg__status--disabled': !loading && stormGuardState === TOGGLE_SWITCH_STATUS.OFF,
      })}
      onClick={onClickNavArrow}
      role="button"
      tabIndex={0}
      onKeyDown={onClickNavArrow}
    >
      <div className="sg__wrapper">
        <div className="sg__inner-wrapper">
          <div className="sg__title-toggle">
            <div className="bp__card-view--title sg__title">
              {sgTitle}
              <div className="sg__info" onClick={onClickInfo} onKeyDown={() => {}} role="button" tabIndex="0">
                {isSource(SOURCE_TYPE.ITK) ? <Icon src="itkHelp" /> : <Icon src="info" />}
              </div>
            </div>

            {!showArrow && renderRightComponent()}
          </div>

          {!(isWeb() && !showArrow) && renderToggleStatus()}
          {sgDescription && <div className="bp__card-view--desc">{sgDescription}</div>}
        </div>

        {(isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) ||
          isFeatureSupported(FEATURE_KEYS.STORM_GUARD_EVSE_OPTOUT)) &&
          showArrow &&
          renderRightComponent()}

        {renderEvForStormGuard()}
      </div>
      {renderApplyButton()}
    </section>
  )
}

StormGuard.propTypes = {
  showArrow: PropTypes.bool,
}

StormGuard.defaultProps = {
  showArrow: false,
}
